#avmax {
    &.page_main {
        /* font-family: Golos_Text; */
        font-weight: medium;
    
        .main__text {
            max-width: 480px;
            font-size: 28px;
        }
    
        .main__buttons {
            display: flex;
    
            .btn_left {
                margin-right: 30px !important;
            }
        }
    }

    &.page_chelnok, &.page_k5drive {
        .modal--app .tabs {
            row-gap: 0;
        }
    }

    &.page_k5drive {
        .modal_hidden {
            display: none !important;
        }

        .modal_android, .modal_ios, .modal_huawei {
            .modal__inner {
                margin-top: -15vh;
            }
        }
    }
}